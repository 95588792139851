<template>
	<div class="home">
		<a-breadcrumb>
			<a-breadcrumb-item href="">
				<a-icon type="home" />
			</a-breadcrumb-item>
			<a-breadcrumb-item href="">
				<a-icon type="schedule" />
				<span>预约</span>
			</a-breadcrumb-item>
			<a-breadcrumb-item> 用户管理 </a-breadcrumb-item>
		</a-breadcrumb>

		<div class="search-list-box">
			<a-form class="ant-advanced-search-form" :form="form" @submit="handleSearch">
				<a-row :gutter="24">
					<a-col :span="4">
						<a-form-item :label="`昵称：`">
							<a-input placeholder="请输入昵称" v-model="searchData.nike_name" />
						</a-form-item>
					</a-col>
					<a-col :span="4">
						<a-form-item :label="`姓名：`">
							<a-input placeholder="请输入姓名" v-model="searchData.real_name" />
						</a-form-item>
					</a-col>
					<a-col :span="4">
						<a-form-item :label="`手机号码：`">
							<a-input placeholder="请输入手机号码" v-model="searchData.mobile" />
						</a-form-item>
					</a-col>
					<a-col :span="4">
						<a-button type="primary" html-type="submit" @click="getUserList"> 查询 </a-button>
						<a-button :style="{ marginLeft: '8px' }" @click="handleReset">
							重置
						</a-button>
					</a-col>
				</a-row>
			</a-form>
			<div class="search-result-list">
				<div class="table-header-box">
					<div class="left">
						<h3 class="title">用户列表</h3>
					</div>
					<div class="right">
						<a-button type="primary" class="marginr20" @click="showAddEdit('edit')">
							详情
						</a-button>
						<a-button type="primary" class="marginr20" @click="checkUser">
							审核
						</a-button>
						<a-button type="primary" class="marginr20" @click="deleteUser">
							批量删除
						</a-button>
					</div>
				</div>
				<a-table :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :columns="columns" :total="total_page * 10"
				 :data-source="userList" rowKey="user_id" :scroll="{ x: 1000, }" :show-total="(total) => `Total ${total} items`"
				 size="small">
				</a-table>
			</div>
		</div>

		<a-modal v-model="addEditShow" title="编辑用户" width="800px" :footer="null">
			<a-spin :spinning="detailLoading">
				<a-form-model ref="ruleForm" :model="info" :rules="rules" :label-col="{ span: 7 }" :wrapper-col="{ span: 12 }"
				 style="margin: 0 auto; width: 100%">
					<a-row :gutter="24">
						<a-col :span="12">
							<a-form-model-item label="用户类型：" prop="user_type">
								<a-select style="width: 100%" placeholder="请选择性别" v-model="info.user_type">
									<a-select-option v-for="(item,index) in typeList" :key="index" :value="index">
										{{item}}
									</a-select-option>
								</a-select>
							</a-form-model-item>
						</a-col>
						<a-col :span="12">
							<a-form-model-item label="昵称：" prop="nike_name">
								<a-input v-model="info.nike_name" :disabled="info.user_id" placeholder="请输入昵称" />
							</a-form-model-item>
						</a-col>				
						<a-col :span="12">
							<a-form-model-item label="真实姓名：" prop="real_name">
								<a-input v-model="info.real_name" placeholder="请输入真实姓名" />
							</a-form-model-item>
						</a-col>
						<a-col :span="12">
							<a-form-model-item label="性别：" prop="gender">
								<a-select style="width: 100%" placeholder="请选择性别" v-model="info.gender">
									<a-select-option v-for="(item,index) in genderList" :key="index" :value="index">
										{{item}}
									</a-select-option>
								</a-select>
							</a-form-model-item>
						</a-col>
						<a-col :span="12">
							<a-form-model-item label="手机号码：" prop="mobile">
								<a-input v-model="info.mobile" placeholder="请输入手机号码" />
							</a-form-model-item>
						</a-col>
						<a-col :span="12">
							<a-form-model-item label="选择地区：" prop="province">
								<area-select @change="selectArea"  :default-value="info.province ? [info.province , info.city, info.district] : []" />
							</a-form-model-item>
						</a-col>
						<a-col :span="12">
							<a-form-model-item label="详细地址：" prop="address">
								<a-input v-model="info.address" placeholder="请输入详细地址" />
							</a-form-model-item>
						</a-col>
						<a-col :span="12">
							<a-form-model-item label="是否认证通过：" prop="is_check">
								<a-radio-group v-model="info.is_check">
								<a-radio :value="1"> 通过 </a-radio>
								<a-radio :value="0"> 未通过 </a-radio>
								</a-radio-group>
							</a-form-model-item>
						</a-col>
						<a-col :span="12">
							<a-form-model-item label="注册时间：">
								<a-input v-model="info.add_time" :disabled="info.user_id"/>								
							</a-form-model-item>
						</a-col>
						<a-col :span="12">
							<a-form-model-item label="最后登录时间：">
								<a-input v-model="info.last_login_time" :disabled="info.user_id"/>								
							</a-form-model-item>
						</a-col>
					</a-row>
				</a-form-model>

				<div class="model-footer">
					<div class="left" v-if="!isAdd">
						<a-button type="primary" class="marginr20" @click="addUser('edit')">
							修改
						</a-button>
						<a-button class="marginr20" html-type="submit" @click="addEditShow = false">
							取消
						</a-button>
					</div>
					<div class="right" v-if="!isAdd" :key="editIndex">
						<a-button type="dashed" :disabled="isEditBtn('prev')" @click="editToData('prev')" class="" html-type="submit">
							上一条
						</a-button>
						<a-button type="dashed" :disabled="isEditBtn('next')" @click="editToData('next')" class="marginr20" html-type="submit">
							下一条
						</a-button>
					</div>
				</div>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	import http from '../http'
	import AreaSelect from "@/components/City/index.vue";
	import moment from "moment";

	const columns = [
		{
			title: "昵称",
			dataIndex: "nike_name",
		},
		{
			title: "OpenID",
			dataIndex: "openid",
			width: 250,
		},
		{
			title: "类型",
			dataIndex: "user_type",
		},
		{
			title: "姓名",
			dataIndex: "real_name",
		},
		{
			title: "性别",
			dataIndex: "gender",
		},
		{
			title: "手机号码",
			dataIndex: "mobile",
		},
		{
			title: "所属地区",
			dataIndex: "province",
		},
		{
			title: "认证状态",
			dataIndex: "check_txt",
		},
		{
			title: "注册时间",
			dataIndex: "add_time",
		},
		{
			title: "最后登录时间",
			dataIndex: "last_login_time",
		},
		
	];

	export default {
		data() {
			return {
				expand: false,
				form: this.$form.createForm(this, {
					name: "advanced_search"
				}),
				columns,
				// 添加与编辑的数据
				info: {
					user_id: undefined,
					nike_name: '',
					real_name: '',
					address: '',
					mobile:'',
					gender: undefined,
					user_type: undefined,
					province: '',
					city: '',
					district: '',
				},
				typeList:[
					'个人',
					'单位',
					'银行机构'
				],
				genderList:[
					'未知',
					'男',
					'女'
				],
				rules: {
					user_type: [{
						required: true,
						message: '请选择类型',
						trigger: 'change'
					}, ],
					nike_name: [{
						required: true,
						message: '请输入昵称',
						trigger: 'blur'
					}, ],
					real_name: [{
						required: true,
						message: '请输入姓名',
						trigger: 'blur'
					}, ],
					mobile: [{
						required: true,
						message: '请输入手机号码',
						trigger: 'blur'
					}, ],
					gender: [{
						required: true,
						message: '请选择性别',
						trigger: 'change'
					}, ],
					province: [{
						required: true,
						message: '请选择区域',
						trigger: 'blur'
					}, ],
					address: [{
						required: true,
						message: '请输入详细地址',
						trigger: 'blur'
					}, ],
				},

				// 搜索参数
				searchData: {
					nike_name: '',
					real_name: '',
					mobile: '',
					page: 1, // 页码
				
				},
				// 多少条数据 * 10
				total_page: 0,
				userList: [],

				addEditShow: false,
				isAdd: false,

				// 选择的数据
				selectedRowDataList: [],
				selectedRowKeys: [],
				// 当前是第几条编辑信息
				editIndex: 0,

				detailLoading: false,
				selectedArea: [],
				
				citySelect: ''
			};
		},
		mounted() {
			this.getUserList()
		},
		components: {
			AreaSelect
		},
		methods: {
			moment,
			handleSearch(e) {
				e.preventDefault();
				this.form.validateFields((error, values) => {
				});
			},

			handleReset() {
				this.searchData = {
					nike_name: '',
					real_name: '',
					mobile: '',
					page: 1, // 页码
				}
				this.getUserList()
			},
			// 删除
			deleteUser() {
				// 判断是否已经选择数据
				if (this.selectedRowKeys.length == 0) {
					this.$message.warning('请先选择用户');
					return
				}
				this.$confirm({
					title: '是否删除选中用户?',
					okText: '确定',
					okType: 'danger',
					cancelText: '取消',
					onOk: () => {
						let selectDatas = []
						this.userList.forEach((v) => {
							if (this.selectedRowKeys.includes(v.user_id)) {
								selectDatas.push(v.user_id)
							}
						})
						http({
							method: 'post',
							url: '/api.user.del/',
							data: {
								user_id: selectDatas.join()
							}
						}).then((res) => {
							if (res.code == '200') {
								this.$message.success('删除用户成功');
								this.getUserList()
							} else {
								this.$message.error(res.message);
							}
						})
					}
				});
			},

			//选择地区之后的回调
			selectArea(selectedArea) {
				this.selectedArea = selectedArea
				this.info.province = selectedArea[0]
				this.info.city = selectedArea[1]
				this.info.district = selectedArea[2]
			},
			// 请求用户列表
			getUserList() {
				http({
					method: 'post',
					url: '/api.user.list/',
					data: this.searchData
				}).then((res) => {
					this.userList = res.data.list
					this.total_page = res.data.total_page
				})
			},
			// 添加用户
			addUser(type) {
				this.$refs.ruleForm.validate(valid => {
					if (valid) {
						delete this.info.add_time
						delete this.info.last_login_time
						http({
							method: 'post',
							url: '/api.user.add/',
							data: {
								...this.info,
								user_id: this.info.user_id || 0,
							}
						}).then((res) => {
							if (res.code == '200') {
								if (!type) {
									this.addEditShow = false
								}
								this.$message.success(type == 'edit' ? '修改用户信息成功' : '添加用户成功');
								this.getUserList()
							} else {
								this.$message.error(res.message);
							}
						})
					} else {
						this.$message.warning('请检查是否已经输入必填项!');
						return false;
					}
				});
			},
			// 表格选择
			onSelectChange(selectedRowKeys) {
				console.log('selectedRowKeys changed: ', selectedRowKeys);
				this.selectedRowKeys = selectedRowKeys;
			},
			// 显示 添加与编辑 的 弹窗
			showAddEdit(type) {
				if (type == 'add') {
					// 添加初始数据
					this.info = {
						user_id: undefined,
						user_type: undefined,
						nike_name: '',
						real_name: '',
						address: '',
						mobile: '',
						gender: undefined,
						province: '',
						city: '',
						district: '',
					}
					this.isAdd = true
				} else if (type == 'edit') {
					// 判断是否已经选择数据
					if (this.selectedRowKeys.length == 0) {
						this.$message.warning('请先选择用户');
						return
					}

					let selectDatas = []
					this.userList.forEach((v) => {
						if (this.selectedRowKeys.includes(v.user_id)) {
							selectDatas.push(v)
						}
					})
					this.selectedRowDataList = JSON.parse(JSON.stringify(selectDatas))
					this.editIndex = 0
					this.getWebTextDetail()
					this.isAdd = false
				}

				this.addEditShow = true
			},
			checkUser() {
				// 判断是否已经选择数据
				if (this.selectedRowKeys.length == 0) {
					this.$message.warning('请先选择用户');
					return
				}

				this.$confirm({
					title: '是否通过认证选中用户?',
					okText: '确定',
					okType: 'danger',
					cancelText: '取消',
					onOk: () => {
						let selectDatas = []
						this.userList.forEach((v) => {
							if (this.selectedRowKeys.includes(v.user_id)) {
								selectDatas.push(v.user_id)
							}
						})
						http({
							method: 'post',
							url: '/api.user.tocheck/',
							data: {
								user_id: selectDatas.join()
							}
						}).then((res) => {
							if (res.code == '200') {
								this.$message.success('用户审核操作成功');
								this.getUserList()
							} else {
								this.$message.error(res.message);
							}
						})
					}
				});
			},
			// 详情
			getWebTextDetail() {
				this.detailLoading = true
				http({
					method: 'post',
					url: '/api.user.detail/',
					data: {
						user_id: this.selectedRowDataList[this.editIndex].user_id
					}
				}).then((res) => {
					if(res.code=='200'){
						this.info = res.data
						this.selectedArea = [this.info.province, this.info.city, this.info.district]
						this.detailLoading = false
					}
				})
			},
			// 判断是否可以点击上一条 或者 下一条、
			isEditBtn(type) {
				if (type == 'prev') {
					if (this.editIndex == 0) {
						return true
					}
					if (this.selectedRowDataList == 1) {
						return true
					}
					if (this.editIndex >= 1) {
						return false
					}
				} else if (type == 'next') {
					if ((this.selectedRowDataList.length - 1) == this.editIndex) {
						return true
					}
				}
			},
			// 点击上一条 或者 下一条、
			editToData(type) {
				let index = -1
				if (type == 'prev') {
					index = this.editIndex - 1
					if (index == 0) {
						index = 0
					}
				} else if (type == 'next') {
					index = this.editIndex + 1
				}
				this.editIndex = index
				this.getWebTextDetail()
			},
		},
	};
</script>
<style lang="less">
	.ant-advanced-search-form {
		padding: 24px;
		background: #fbfbfb;
		border: 1px solid #d9d9d9;
		border-radius: 6px;
	}

	.ant-advanced-search-form .ant-form-item {
		display: flex;
	}

	.ant-advanced-search-form .ant-form-item-control-wrapper {
		flex: 1;
	}

	.search-list-box .ant-form {
		max-width: none;
	}

	.search-list-box .search-result-list {
		margin-top: 16px;
		border: 1px dashed #e9e9e9;
		border-radius: 6px;
		background-color: #fafafa;
		min-height: 200px;
		text-align: center;
		padding-top: 80px;
	}


	.tes {
		width: 90%;
		margin: 0 auto;
		border: 1px solid #e8e8e8;
		border-radius: 3px;

		.table-th {
			display: flex;
			align-items: center;
			border-bottom: 1px solid #e8e8e8;
			height: 30px;

			div {
				width: 33.33%;
				box-sizing: border-box;
				height: 30px;
				padding-left: 20px;
				font-weight: 700;
				border-right: 1px solid #e8e8e8;
				display: flex;
				align-items: center;

				&:last-child {
					border-right: none;
				}
			}
		}

		.taes {
			display: flex;
			align-items: center;

			&>div {
				width: 33.33%;
			}

			.chebox {
				overflow: hidden;
				box-sizing: border-box;
				height: 50px;
				display: flex;
				align-items: center;
				border-bottom: 1px solid #e8e8e8;

				&:last-child {
					border-bottom: none;
				}

				.chebox-item {
					box-sizing: border-box;
					padding-left: 20px;
				}
			}

			.left {
				height: 300px;
				border-right: 1px solid #e8e8e8;

				.chebox {
					height: 100%;
				}
			}

			.contet {
				height: 300px;
				border-right: 1px solid #e8e8e8;
			}

			.right {
				height: 300px;
			}
		}
	}
</style>
